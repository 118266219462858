<template>
  <default-layout
    route-name="Ajuste de Faturas"
    @search="search"
    :loading="loading"
  >
    <template #header-actions>
      <v-spacer />

      <default-save-button
        @click="dialogOpt = true"
        :disabled="files.length == 0"
        :loading="uploading"
      >
        Enviar
      </default-save-button>
    </template>

    <template #content>
      <invoice-search-dialog ref="invoice_search_dialog" />

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <file-dialog
              v-if="!rerenderFileDialog"
              v-model="files"
              file-add-button-message="Enviar Arquivo"
              file-upload-button-message="Clique Aqui para Adicionar Arquivos para Ajuste de Faturas"
              :accepted-formats="FileHandle.acceptedFormats"
              can-manipulate
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <write-off-payment-table
              :items="items"
              :pagination="pagination"
              @download="download"
              @search_invoices="search_invoices"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="dialogOpt" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Ajuste de Fatura
          </v-card-title>

          <v-card-text>
            <v-container class="px-0" fluid>
              <v-radio-group v-model="payType" row>
                <v-radio label="Baixa de Fatura" value="W"></v-radio>
                <v-radio
                  v-if="canInvoiceChangeStatus"
                  label="Cobrança Jurídica"
                  value="L"
                ></v-radio>
                <v-radio
                  v-if="canInvoiceChangeStatus"
                  label="Entrada de Cobrança"
                  value="E"
                ></v-radio>
              </v-radio-group>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="upload()" :disabled="!payType" text>
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </default-layout>
</template>

<script>
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import WriteOffPaymentTable from "./components/WriteOffPaymentTable.vue";
import Http from "./domain/Http";
import FileDialog from "@/components/FileUpload/FileDialog.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";

import FileHandle from "./domain/FileHandle";
import InvoiceSearch from "./domain/InvoiceSearch";
import InvoiceSearchDialog from "./components/InvoiceSearchDialog/InvoiceSearchDialog.vue";

export default {
  components: {
    DefaultLayout,
    WriteOffPaymentTable,
    FileDialog,
    DefaultSaveButton,
    InvoiceSearchDialog
  },
  name: "WriteOffPayment",
  data() {
    return {
      items: [],
      files: [],
      uploading: false,
      loading: false,
      FileHandle,
      InvoiceSearch,
      Http,
      rerenderFileDialog: false,
      dialogOpt: false,
      payType: "W",
      canInvoiceChangeStatus: false
    };
  },
  mixins: [PaginationMixin, AuthorizationMixin],
  async created() {
    this.loadData();
    this.canInvoiceChangeStatus = await this.canAccess({
      route: "/invoice/change-status",
      type: "exec"
    });
  },
  methods: {
    loadData() {
      const loadData = this.Http.loadData.bind(this);

      loadData();
    },
    async upload() {
      const count = this.files.filter(file => !file.removed);

      if (count.length > 0) {
        this.dialogOpt = false;
        this.files.map(file => (file.payType = this.payType));
        const upload = this.FileHandle.upload.bind(this);

        await upload();

        this.files = [];

        await this.loadData();
      } else {
        this.dialogOpt = false;
        this.showToast({
          message: "Selecione um arquivo!",
          status: "error"
        });
      }
    },
    download(item) {
      const download = this.FileHandle.download.bind(this);

      download(item);
    },
    async search_invoices(item) {
      this.loading = true;
      const invoices = await this.InvoiceSearch.getInvoices(item);
      const invoiceSearchDialog = this.$refs["invoice_search_dialog"];
      invoiceSearchDialog.search(invoices.write_off_payments);
      this.loading = false;
    }
  }
};
</script>

<style></style>
